import React, { useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadFull } from "tsparticles";
import logo from "./logo.svg";
import "./App.css";
import particlesOptions from "./particles.json";
import HeroText from "./components/HeroText/HeroText";
import Parallax from "./components/Parallax/Parallax";
import About from "./components/About/About";
import ShowCase from "./components/ShowCase/ShowCase";

function App() {
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (init) {
      return;
    }

    initParticlesEngine(async (engine) => {
      await loadFull(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);
  (function () {
    // Create the button element
    const aiButton = document.createElement("div");
    aiButton.id = "ai-assistant-button";
    aiButton.innerHTML = "💬 Ask My AI";

    // Apply styles
    Object.assign(aiButton.style, {
      position: "fixed",
      bottom: "20px",
      right: "20px",
      background: "#007bff",
      color: "white",
      padding: "12px 18px",
      borderRadius: "50px",
      cursor: "pointer",
      fontSize: "16px",
      fontFamily: "Arial, sans-serif",
      fontWeight: "bold",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      transition: "background 0.3s ease, transform 0.1s ease",
    });

    // Hover effect
    aiButton.addEventListener("mouseenter", () => {
      aiButton.style.background = "#0056b3";
      aiButton.style.transform = "scale(1.05)";
    });
    aiButton.addEventListener("mouseleave", () => {
      aiButton.style.background = "#007bff";
      aiButton.style.transform = "scale(1)";
    });

    // Click event
    aiButton.addEventListener("click", () => {
      window.open(
        "https://ddna-tecnoartesanoscom096e--space-avatar.soului.dh.soulmachines.cloud/?sig=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3NDA4MzkyNjgsImlzcyI6InNpZ25lZF91cmwtMTRkNGFmODItODVkNC00MmNiLWJhNjktNTE2M2EzODViYzNiIiwiZXhwIjoxODI3MTUyODY4LCJlbWFpbCI6InRlY25vYXJ0ZXNhbm9zY29tMDk2ZS0tc3BhY2UtYXZhdGFyQGRkbmEuc3R1ZGlvIiwic291bElkIjoiZGRuYS10ZWNub2FydGVzYW5vc2NvbTA5NmUtLXNwYWNlLWF2YXRhciJ9.9GoM9tmpv-xdi16xL3xdwmHTBC2iHGQXeRm-HuADgX8",
        "_blank"
      );
    });

    // Append the button to the body
    document.body.appendChild(aiButton);
  })();

  return (
    <div className="App">
      {init && <Particles options={particlesOptions} />}
      <div>
        {/* 
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <p>
            Edit <code>src/particles.json</code> to customize Particles, then
            save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
          <a
            className="App-link"
            href="https://particles.js.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            See Particles samples
          </a>
        </header>*/}
        <HeroText />
        <Parallax />
      </div>

      <div>
        <About />
      </div>
      <ShowCase />
    </div>
  );
}

export default App;
